import Loader from 'components/Loader'
import CategoriesItem from 'components/navbar/burger-menu/CategoriesItem'
import SubMenuContainer from 'components/navbar/burger-menu/SubMenuContainer'
import { fetchMainCategoriesWithFirstChild } from 'http/productAPI'
import { useEffect, useState } from 'react'

const CategoriesListMenu = ({ menuActive, setMenuActive }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataForSubMenu, setDataForSubMenu] = useState([])
  const [mainCategories, setMainCategories] = useState([])
  const [categoryParentId, setCategoryParentId] = useState()

  const fetchMainCategories = async () => {
    setLoading(true)
    const data = await fetchMainCategoriesWithFirstChild()
    setMainCategories(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchMainCategories()
  }, [])

  function handleOpenSubMenu(arr) {
    if (arr && arr.length > 0) {
      setOpenSubMenu(true)
      setDataForSubMenu(arr)
    } else {
      setOpenSubMenu(false)
    }
  }

  function handleCloseSubMenu() {
    setOpenSubMenu(false)
  }

  return (
    menuActive && (
      <div>
        <div className='fixed top-20 inset-0 bg-black bg-opacity-50'></div>
        <div
          className='absolute top-20 left-0 md:left-8 w-80 shadow-md bg-blue-50 h-[70vh]'
          onMouseEnter={() => setMenuActive(true)}
          onMouseLeave={() => setMenuActive(false)}
        >
          <div
            className='h-[70vh] overflow-y-scroll pr-4 -mr-4'
            style={{
              scrollbarWidth: 'none' /* Firefox */,
              msOverflowStyle: 'none' /* IE та Edge */
            }}
          >
            {loading ? (
              <Loader />
            ) : mainCategories.length > 0 ? (
              mainCategories.map((item, i) => (
                <CategoriesItem
                  key={i}
                  item={item}
                  setCategoryParentId={setCategoryParentId}
                  handleOpenSubMenu={handleOpenSubMenu}
                  handleCloseSubMenu={handleCloseSubMenu}
                  setMenuActive={setMenuActive}
                />
              ))
            ) : (
              <div className='p-8'>Категорії відсутні </div>
            )}
          </div>
          {openSubMenu && (
            <SubMenuContainer
              categoryParentId={categoryParentId}
              dataForSubMenu={dataForSubMenu}
              setOpenSubMenu={setOpenSubMenu}
              setMenuActive={setMenuActive}
            />
          )}
        </div>
      </div>
    )
  )
}

export default CategoriesListMenu
