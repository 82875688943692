import altLogo from 'img/altLogo1.png'

const Loader = () => {
  return (
    <div className='flex items-center justify-center flex-col'>
      <img className='w-24 h-24 animate-pulse-scale' src={altLogo} alt='Loading...' />
      <p className='mt-2'>Завантаження...</p>
    </div>
  )
}

export default Loader
