const ButtonActionModal = ({ icon = null, title, action, disabled = false }) => {
  return (
    <button
      onClick={action}
      disabled={disabled}
      className={`border border-solid border-firmBlue rounded-lg shadow-sm p-2 
    flex items-center justify-center gap-1 whitespace-nowrap 
    ${disabled ? 'bg-gray-300' : 'bg-blue-200 hover:bg-blue-100'}`}
    >
      {icon}
      {title}
    </button>
  )
}

export default ButtonActionModal
