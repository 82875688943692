const ActionModal = ({ children, closeAction }) => {
  return (
    <div className='fixed top-20 left-0 w-full h-screen flex justify-center items-center'>
      <div
        className='absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm'
        onClick={() => closeAction(false)}
      ></div>

      <div className='relative z-10  bg-blue-50 p-4 rounded shadow-lg lg:w-3/5 w-11/12 '>
        <div className='text-end cursor-pointer text-gray-500' onClick={() => closeAction(false)}>
          X
        </div>
        {children}
      </div>
    </div>
  )
}

export default ActionModal
