import { CATALOG_ROUTE } from 'constants/consts'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import clients from '../../img/advantage/11.png'
import catrgorysName from '../../img/advantage/12.png'
import quality from '../../img/advantage/13.png'
import partners from '../../img/advantage/14.png'
import asort from '../../img/asort.png'
import './Mainpage.css'
import PPR from '../../img/PPRIndastry.jpg'
import steel from '../../img/SteelIndustry.jpg'
import warehouse from '../../img/warehouse.jpg'
import AnimationComponent1 from './animation1'
import AnimationComponent2 from './animation2'

function MainPage() {
  const navigate = useNavigate()

  return (
    <div className='MainPage'>
      <div className='MainPage__conteiner'>
        <div className='MainPage__foto'>
          <img src={warehouse} alt='warehouse' />
        </div>

        <div className='MainPage__content'>
          <div className='MainPage__slider'>
            <div className='MainPage__sliderText'>
              <p className='slider__topText'>
                <span>Пропонуємо</span>
              </p>
              <p className='slider__bottomText'>
                <span>Широкий асортимент</span>
              </p>
              <input
                className='Slider__button'
                type='button'
                value='Каталог'
                onClick={() => navigate(CATALOG_ROUTE)}
              ></input>
            </div>
            <div className='MainPage__sliderImg'>
              <img src={asort} alt='asort' />
            </div>
          </div>
        </div>

        <div className='MainPage__categories'>
          <h1 className='text-2xl'>Власне виробництво</h1>
        </div>

        <div className='MainPage__industry'>
          <div className='Industry__img'>
            <img src={steel} alt='ppr industry' />
          </div>
          <AnimationComponent1 />
        </div>

        <div className='MainPage__advantage'>
          <h1 className='text-2xl'>Наші переваги </h1>

          <div className='Advantage__container'>
            <div className='Advantage__item'>
              <div className='Advantage__itemImg'>
                <img src={clients} alt='clients' />
              </div>
              <p className='Advantage__textTop'>900+</p>
              <p className='Advantage__textBottom'>Кліентів</p>
            </div>
            <div className='Advantage__item'>
              <div className='Advantage__itemImg'>
                <img src={catrgorysName} alt='catrgorysName' />
              </div>
              <p className='Advantage__textTop'>3000+</p>
              <p className='Advantage__textBottom'>Найменувань товару</p>
            </div>
            <div className='Advantage__item'>
              <div className='Advantage__itemImg'>
                <img id='itemImg1' src={quality} alt='quality' />
              </div>
              <p className='Advantage__textTop'>100%</p>
              <p className='Advantage__textBottom'>Якості</p>
            </div>
            <div className='Advantage__item'>
              <div className='Advantage__itemImg'>
                <img src={partners} alt='partners' />
              </div>
              <p className='Advantage__textTop'>530</p>
              <p className='Advantage__textBottom'>Партнерів</p>
            </div>
          </div>
        </div>

        <div className='MainPage__industry'>
          <div className='Industry__img'>
            <img src={PPR} alt='' />
          </div>
          <AnimationComponent2 />
        </div>
      </div>
    </div>
  )
}

export default MainPage
