import ActionModal from 'components/navbar/userActions/ActionModal'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import { ADMIN_ROUTE, REGISTRATION_ROUTE } from 'constants/consts'
import { loginUser } from 'http/userApi'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addUser, signOut } from 'redux/toolkitSlice'

const UserInfo = ({ setUserActive }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')

  const { user } = useSelector(state => state.toolkit)

  const formatPhone = value => {
    let digitsOnly = value.replace(/[^\d]/g, '') // Видаляємо всі нечислові символи
    const maxLength = 12 // Максимальна довжина номера телефону

    let formattedTelef = '+38 '

    if (digitsOnly.length > maxLength) {
      digitsOnly = digitsOnly.slice(0, maxLength)
    }

    if (digitsOnly.length >= 3) {
      formattedTelef += `(${digitsOnly.slice(2, 5)}) `
    }

    if (digitsOnly.length >= 6) {
      formattedTelef += `${digitsOnly.slice(5, 8)}-`
    }

    formattedTelef += digitsOnly.slice(8)

    return formattedTelef
  }

  const handlePhoneChange = e => {
    const { value } = e.target
    const formattedPhone = formatPhone(value)
    setPhone(formattedPhone)
  }

  const signIn = () => {
    const post = {
      telef: phone,
      password: password
    }

    if (phone.length < 18) {
      alert('Номер телефону введено не коректно')
    } else {
      loginUser(post)
        .then(data => dispatch(addUser(data)))
        .catch(error => {
          console.error(error)
          if (error.message === 'Невірний пароль') {
            alert('Невірний пароль')
          } else if (error.message === 'Користувача не знайдено') {
            alert('Користувача не знайдено')
          } else {
            alert('Помилка входу')
          }
        })
    }
  }

  return (
    <ActionModal closeAction={setUserActive}>
      <div>
        {user ? (
          <div className=''>
            <p className='text-center text-2xl'>
              Добрий день! {user.firstName} {user.secondName}
            </p>
            {user.opt &&
              user.manager(
                <div>
                  <p>Ваш персональний менеджер {user.manager.name}</p>
                  <p>Телефон менеджера:{user.manager.tel}</p>
                </div>
              )}
            {user.admin && (
              <input className='' type='button' value='Адмін панель' onClick={() => navigate(ADMIN_ROUTE)} />
            )}
            <ButtonActionModal title={'Вийти'} action={() => dispatch(signOut(false))} />
          </div>
        ) : (
          <div className='flex items-center justify-center gap-2 flex-col'>
            <p className='text-center text-2xl'>Увійти за номером телефону</p>
            <input
              className='p-2 sm:w-1/2 w-11/12'
              value={phone}
              onChange={handlePhoneChange}
              type='text'
              placeholder='Номер телефону'
            />
            <input
              className='p-2 sm:w-1/2 w-11/12'
              value={password}
              onChange={e => setPassword(e.target.value)}
              type='text'
              placeholder='Пароль'
            />
            <div className='flex items-center justify-center gap-2'>
              <ButtonActionModal title={'Увійти'} action={signIn} />
              <ButtonActionModal title={'Реєстрація'} action={() => navigate(REGISTRATION_ROUTE)} />
            </div>
          </div>
        )}
      </div>
    </ActionModal>
  )
}

export default UserInfo
