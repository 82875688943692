export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const CATALOG_ROUTE = '/catalog'
export const BASKET_ROUTE = '/basket'
export const CONTACTS_ROUTE = '/contacts'
export const MAIN_ROUTE = '/main'
export const DELIVERY_ROUTE = '/delivery'
export const CONDITION_ROUTE = '/condition'
export const CARD_ROUTE = '/product'
export const REGISTRATION_ROUTE = '/registration'
export const CONFIRMATION_ROUTE = '/confirmation'
export const CATEGORY_ROUTE = '/category'
export const PRODUCTS_ROUTE = '/products'
