import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import { CARD_ROUTE } from 'constants/consts'
import altLogo from 'img/altLogo.png'
import ActionButtonProductItem from 'pages/catalog/ProductItem/ActionButtonProductItem'
import RateAndProductId from 'pages/catalog/ProductItem/RateAndProductId'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ProductItem = ({ product }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className='w-full md:w-1/2 lg:w-1/3 xl:w-1/4 min-w-min border border-solid border-gray-300 cursor-pointer p-2  hover:bg-gray-200'>
      <div onClick={() => navigate(location.pathname + '/' + product.id)}>
        <div className='block w-80 overflow-hidden mx-auto'>
          <img
            className='w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105'
            src={product.img && product.img.length > 0 ? product.img[0] : altLogo}
            alt=''
          />
        </div>
        <div className='line-clamp-2 break-words h-[3rem] leading-[1.5rem]'>{product.name}</div>

        <RateAndProductId product={product} />

        {/*price*/}
        <div className='text-xl font-medium'>
          <PriceForRetailOrWholesale price={product.priceUSD} /> грн
        </div>
      </div>
      <ActionButtonProductItem product={product} />
    </div>
  )
}

export default ProductItem
