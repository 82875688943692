import { $host } from './index'

export const fetchMainCategoriesWithFirstChild = async () => {
  try {
    const { data } = await $host.get('api/product/main-categories-with-first-child')
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання списку товарів')
  }
}

export const fetchCategoryWithoutParent = async () => {
  try {
    const { data } = await $host.get('api/product/category-without-parent')
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання списку товарів')
  }
}

export const fetchCategoryByID = async id => {
  try {
    const { data } = await $host.post('api/product/category-by-parent-id', { id: id })
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання списку товарів')
  }
}

export const fetchOneProduct = async (categoryId, productId) => {
  try {
    const { data } = await $host.post('api/product/product-by-id-and-category-id', {
      categoryId: categoryId,
      productId: productId
    })
    return data
  } catch (error) {
    console.error(error)
    throw new Error('Помилка отримання товару')
  }
}
