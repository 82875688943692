import SubMenuItem from 'components/navbar/burger-menu/SubMenuItem'

const SubMenuContainer = ({ dataForSubMenu, setOpenSubMenu, setMenuActive, categoryParentId }) => {
  return (
    <div
      className='absolute top-0 left-full w-[74vw] h-full bg-white shadow-md flex items-start justify-start flex-wrap
    p-4 content-baseline'
      onMouseEnter={() => setOpenSubMenu(true)}
    >
      {dataForSubMenu &&
        dataForSubMenu.map((item, index) => (
          <SubMenuItem
            key={index}
            item={item}
            setMenuActive={setMenuActive}
            setOpenSubMenu={setOpenSubMenu}
            categoryParentId={categoryParentId}
          />
        ))}
    </div>
  )
}

export default SubMenuContainer
