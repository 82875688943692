import Basket from 'components/navbar/userActions/Basket'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import QuantityBlock from 'pages/catalog/ProductItem/QuantityBlock'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddBasket, AddFavorit, RemoveBasket, RemoveFavorit, setBasketActive } from 'redux/toolkitSlice'
import { ReactComponent as ShoppingCart } from 'img/icons/shopping-cart.svg'
import { ReactComponent as Heart } from 'img/icons/heart.svg'
import { ReactComponent as HeartNotFilled } from 'img/icons/heart-not-filled.svg'
import { ReactComponent as Check } from 'img/icons/check-circle.svg'

const ActionButtonProductItem = ({ product }) => {
  const dispatch = useDispatch()

  const [quantity, setQuantity] = useState(1)

  const { basket, favorite } = useSelector(state => state.toolkit)
  const { basketActive } = useSelector(state => state.toolkit)

  const hasBasketProduct = basket.find(item => item.id === product.id)

  return (
    <div className='mt-2 flex items-center justify-between'>
      {hasBasketProduct ? (
        <ButtonActionModal
          icon={<Check className='w-7 h-5 fill-emerald-500' />}
          title={'В корзині'}
          action={() => dispatch(RemoveBasket(product))}
        />
      ) : (
        <ButtonActionModal
          icon={<ShoppingCart className='w-7 h-5' />}
          title={'В корзину'}
          action={() =>
            dispatch(
              AddBasket({
                product: product,
                quantity: quantity
              })
            )
          }
        />
      )}
      {!hasBasketProduct && <QuantityBlock quantity={quantity} setQuantity={setQuantity} />}
      {favorite.includes(product) ? (
        <Heart className='w-10 h-8 fill-firmBlue cursor-pointer' onClick={() => dispatch(RemoveFavorit(product))} />
      ) : (
        <HeartNotFilled
          className='w-10 h-8 fill-firmBlue cursor-pointer'
          onClick={() => {
            dispatch(AddFavorit(product))
          }}
        />
      )}

      {basketActive && <Basket setBasketActive={setBasketActive} />}
    </div>
  )
}

export default ActionButtonProductItem
