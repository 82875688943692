import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import { ReactComponent as Plus } from 'img/icons/plus.svg'
import { ReactComponent as Minus } from 'img/icons/minus.svg'
import React from 'react'

const QuantityBlock = ({ quantity, setQuantity }) => {
  return (
    <div className='flex gap-1 justify-center items-center'>
      <ButtonActionModal
        icon={<Minus className='w-5 h-3' />}
        title={''}
        action={() => setQuantity(quantity - 1)}
        disabled={quantity <= 1}
      />
      <input type='text' className='rounded p-1 w-1/3' value={quantity} />
      <ButtonActionModal icon={<Plus className='w-5 h-3' />} title={''} action={() => setQuantity(quantity + 1)} />
    </div>
  )
}

export default QuantityBlock
