import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import ActionButtonProductItem from 'pages/catalog/ProductItem/ActionButtonProductItem'
import RateAndProductId from 'pages/catalog/ProductItem/RateAndProductId'
import ButtonBack from '../../components/ButtonBack'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toolkitSlise } from 'redux/toolkitSlice'
import { fetchOneProduct } from '../../http/productAPI'
import altLogo from '../../img/altLogo.png'

function ProductCard() {
  const { id, product_id } = useParams()
  const [product, setProduct] = useState({})

  const fetchOne = async (categoryId, productId) => {
    const data = await fetchOneProduct(categoryId, productId)
    setProduct(data)
  }

  useEffect(() => {
    fetchOne(id, product_id)
  }, [])

  const formatDescription = description => {
    if (!description) return ''
    const replacedNewlines = description.replace(/\r\n/g, '<br/>')
    const formattedDescription = replacedNewlines.replace(
      /(.*?):(.+?)(<br\/>|$)/g,
      '<strong class="highlight">$1:</strong>$2<br/>'
    )
    return formattedDescription
  }

  return (
    <div className='h-fit min-h-screen'>
      <div className='relative top-20 w-full flex flex-col sm:flex-row justify-center items-start'>
        <div className='w-full sm:w-1/2'>
          <div className='block w-[90%] sm:w-[70%] overflow-hidden mx-auto'>
            <img
              className='w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105'
              src={product.img && product.img.length > 0 ? product.img[0] : altLogo}
              alt=''
            />
          </div>
        </div>
        <div className='w-full sm:w-1/2 p-4 flex flex-col gap-3'>
          <p className='text-3xl'>{product.name}</p>
          <RateAndProductId product={product} />
          <div className='text-xl font-medium'>
            <PriceForRetailOrWholesale price={product.priceUSD} /> грн
          </div>
          <ActionButtonProductItem product={product} />
          <div className=''>
            <p>Опис товару: </p>
            {product.description ? (
              <div dangerouslySetInnerHTML={{ __html: formatDescription(product.description) }}></div>
            ) : (
              <div>скоро з'явиться</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
