import { BrowserRouter } from 'react-router-dom'
import './App.css'
import AppRouter from 'routs/AppRouter'
import Navbar from 'components/navbar/Navbar'
import Footer from './components/Footer'

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <AppRouter />
      <Footer />
    </BrowserRouter>
  )
}

export default App
