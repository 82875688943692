import { createSlice } from '@reduxjs/toolkit'
import 'firebase/firestore'
import { initializeApp } from 'firebase/app'

const app = initializeApp({
  apiKey: 'AIzaSyB1Va3di07ZwWDXMApnoxeD0y7RhmOhQYM',
  authDomain: 'lukon-da46a.firebaseapp.com',
  projectId: 'lukon-da46a',
  storageBucket: 'lukon-da46a.appspot.com',
  messagingSenderId: '315501194090',
  appId: '1:315501194090:web:045996110662e6c2241313',
  measurementId: 'G-ESMSXVWS4J'
})

export const toolkitSlise = createSlice({
  name: 'toolkitUser', // название слайса
  initialState: {
    //    дефолтное состояние
    user: false,
    // userAuth: auth,
    favorite: [],
    basket: [],
    products: [],
    categories: [],
    categoryName: '',
    basketActive: false
  },
  reducers: {
    AddFavorit(state, action) {
      state.favorite.push(action.payload)
    },
    RemoveFavorit(state, action) {
      state.favorite = state.favorite.filter(data => data.id !== action.payload.id)
    },
    AddBasket(state, action) {
      const { product, quantity } = action.payload
      const updatedProduct = {
        ...product,
        quantity,
        amount: product.priceUSD * quantity
      }
      state.basket.push(updatedProduct)
    },
    RemoveBasket(state, action) {
      state.basket = state.basket.filter(data => data.id !== action.payload.id)
    },
    RemoveBasketAll(state, action) {
      state.basket = []
    },
    addUser(state, action) {
      state.user = action.payload
    },
    signOut(state, action) {
      state.user = action.payload
    },
    setProducts(state, action) {
      state.products = action.payload
    },
    setCategories(state, action) {
      state.categories = action.payload
    },
    setCategoryName(state, action) {
      state.categoryName = action.payload
    },
    setBasketActive(state, action) {
      state.basketActive = action.payload
    }
  }
})

export const selectCategories = state => state.categories
export const selectProducts = state => state.products
export const selectCategoryName = state => state.categoryName

export const {
  AddFavorit,
  RemoveFavorit,
  AddBasket,
  RemoveBasket,
  RemoveBasketAll,
  addUser,
  signOut,
  setProducts,
  setCategories,
  setCategoryName,
  setBasketActive
} = toolkitSlise.actions

export default toolkitSlise.reducer
