import BurgerMenu from 'components/navbar/burger-menu/BurgerMenu'
import CategoriesListMenu from 'components/navbar/burger-menu/CategoriesListMenu'
import Basket from 'components/navbar/userActions/Basket'
import Favorite from 'components/navbar/userActions/Favorite'
import UserInfo from 'components/navbar/userActions/UserInfo'
import React, { useState } from 'react'
import usericon from 'img/user.png'
import hart from 'img/hart.png'
import bascet from 'img/bascet.png'
import logo from 'img/logo.png'
import { CONFIRMATION_ROUTE, MAIN_ROUTE } from 'constants/consts'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setBasketActive, toolkitSlise } from 'redux/toolkitSlice'

function Navbar() {
  const navigate = useNavigate()

  const [menuActive, setMenuActive] = useState(false)
  const [userActive, setUserActive] = useState(false)
  const [favoriteActive, setFavoriteActive] = useState(false)
  const [basketActive, setBasketActive] = useState(false)
  const dispatch = useDispatch()
  const { favorite, basket, user } = useSelector(state => state.toolkit)
  const { RemoveFavorit, RemoveBasket, AddBasket, addUser, signOut } = toolkitSlise.actions

  return (
    <header className='w-full bg-headerBackground px-2 flex items-center justify-center absolute z-10'>
      <div className='w-11/12 flex items-center justify-between'>
        {/* Бургер меню */}
        <BurgerMenu setMenuActive={setMenuActive} />

        {/* Лого */}
        <div className='w-60 h-20 cursor-pointer' onClick={() => navigate(MAIN_ROUTE)}>
          <img src={logo} alt='logo' className='object-contain w-full h-full' />
        </div>

        {/* Юзер Екшенс */}
        <div className=''>
          <div className='flex items-center justify-center gap-2'>
            <div
              onClick={() => {
                setUserActive(true)
                setFavoriteActive(false)
                setBasketActive(false)
              }}
            >
              {' '}
              <img src={usericon} alt='user' className='w-8 h-8 cursor-pointer' />
            </div>
            <div
              onClick={() => {
                setFavoriteActive(true)
                setUserActive(false)
                setBasketActive(false)
              }}
            >
              {' '}
              <img src={hart} alt='hart' className='w-8 h-8 cursor-pointer' />
            </div>
            <div
              onClick={() => {
                setBasketActive(true)
                setUserActive(false)
                setFavoriteActive(false)
              }}
            >
              {' '}
              <img src={bascet} alt='bascet' className='w-8 h-8 cursor-pointer' />
            </div>
          </div>
          {favoriteActive && <Favorite setFavoriteActive={setFavoriteActive} />}
          {basketActive && <Basket setBasketActive={setBasketActive} />}
          {userActive && <UserInfo setUserActive={setUserActive} />}
        </div>
      </div>
      <CategoriesListMenu menuActive={menuActive} setMenuActive={setMenuActive} />
    </header>
  )
}

export default Navbar
