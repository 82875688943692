import ActionModal from 'components/navbar/userActions/ActionModal'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import { MAIN_ROUTE } from 'constants/consts'
import { createOrder } from 'http/order'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RemoveBasketAll } from 'redux/toolkitSlice'

const ConfirmOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState({
    fullName: null,
    phone: null,
    city: null
  })
  const [openModal, setOpenModal] = useState(false)
  const { basket } = useSelector(state => state.toolkit)

  const handleInputChange = e => {
    const { name, value } = e.target

    setContacts(prevContacts => ({
      ...prevContacts,
      [name]: value
    }))
  }

  async function sendOrder(data) {
    try {
      await createOrder(data)
      dispatch(RemoveBasketAll())
      setOpenModal(true)
    } catch (e) {
      alert('Виникла непердбачувана помилка. Зателефонуйте у відділ продажу')
    }
  }

  function handleSubmit() {
    if (contacts.fullName && contacts.phone && contacts.city) {
      const data = {
        contacts: contacts,
        order: basket
      }
      sendOrder(data)
    } else {
      alert('Всі поля мають бути заповнені!')
    }
  }

  function handleCloseModal() {
    setOpenModal(false)
    navigate(MAIN_ROUTE)
  }

  return (
    <div className='h-fit min-h-screen'>
      <div className='relative top-20 w-full flex justify-center items-start flex-col p-4'>
        <div className='flex flex-col gap-2 w-full sm:w-1/3'>
          <p className='text-xl'>Введіть контактні данні</p>
          <input
            type='text'
            name='fullName'
            className='p-2 rounded'
            value={contacts.fullName}
            onChange={handleInputChange}
            placeholder="Ім'я та призвіще"
          />
          <input
            type='text'
            name='phone'
            className='p-2 rounded'
            value={contacts.phone}
            onChange={handleInputChange}
            placeholder='Номер телефону'
          />
          <input
            type='text'
            name='city'
            className='p-2 rounded'
            value={contacts.city}
            onChange={handleInputChange}
            placeholder='Місто'
          />
        </div>
        <div className='my-5 text-2xl '>
          <p className=''>Ваше замовлення на сумму:</p>
          <PriceForRetailOrWholesale
            price={basket.reduce((sum, product) => sum + product.amount, 0)}
          /> грн
          <p className='text-sm'>Деталі замовлення можна переглянути та відредагувати в корзині</p>
        </div>
        <ButtonActionModal action={handleSubmit} title={'Підтвердити замовлення'} disabled={false} />
      </div>
      {openModal && (
        <div>
          <ActionModal
            closeAction={handleCloseModal}
            children={
              <div className='text-center'>
                <p className='text-2xl'>Дякуємо за замовлення!</p>
                <p className='text-xl'>Найближчим часом менеджер з вами зв'яжеться</p>
              </div>
            }
          />
        </div>
      )}
    </div>
  )
}

export default ConfirmOrder
