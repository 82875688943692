import ActionModal from 'components/navbar/userActions/ActionModal'
import ButtonActionModal from 'components/navbar/userActions/ButtonActionModal'
import ProductItem from 'components/navbar/userActions/ProductItem'
import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import { CONFIRMATION_ROUTE } from 'constants/consts'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RemoveBasket } from 'redux/toolkitSlice'

const Basket = ({ setBasketActive }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { basket } = useSelector(state => state.toolkit)

  function handleNavigate() {
    navigate(CONFIRMATION_ROUTE)
    setBasketActive(false)
  }

  return (
    <ActionModal closeAction={setBasketActive}>
      <div className=''>
        {basket.length > 0 ? (
          <div className='max-h-[70vh] overflow-auto'>
            {basket.map((item, index) => (
              <ProductItem
                key={index}
                product={item}
                quantity
                actionButtons={<ButtonActionModal title={'Видалити'} action={() => dispatch(RemoveBasket(item))} />}
              />
            ))}
            <div className='my-5'>
              Ваше замовлення на сумму:{' '}
              <PriceForRetailOrWholesale price={basket.reduce((sum, product) => sum + product.amount, 0)} /> грн
            </div>
            <ButtonActionModal icon={null} title={'Оформити замовлення'} action={handleNavigate} />
          </div>
        ) : (
          <div className='text-center text-2xl'>Список замовлення порожній</div>
        )}
      </div>
    </ActionModal>
  )
}

export default Basket
