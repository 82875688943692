import { CATALOG_ROUTE } from 'constants/consts'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as BurgerIcon } from 'img/icons/list.svg'

const BurgerMenu = ({ setMenuActive }) => {
  const navigate = useNavigate()

  return (
    <div
      className='flex items-center justify-center cursor-pointer h-20'
      onClick={() => navigate(CATALOG_ROUTE)}
      onMouseEnter={() => setMenuActive(true)}
      onMouseLeave={() => setMenuActive(false)}
    >
      <BurgerIcon className='w-10 h-8 fill-firmBlue' />
      <p className='ml-2 text-white hidden md:block'>КАТАЛОГ ТОВАРІВ</p>
    </div>
  )
}

export default BurgerMenu
