import Catalog from 'pages/catalog/Catalog'
import ConfirmOrder from 'pages/confirmationOrder/confirmOrder'
import MainPage from 'pages/mainpage/Mainpage'
import ProductCard from 'pages/productCard/productCard'
import Registration from 'pages/registration/registration'
import {
  CARD_ROUTE,
  CATALOG_ROUTE,
  CATEGORY_ROUTE,
  CONDITION_ROUTE,
  CONFIRMATION_ROUTE,
  CONTACTS_ROUTE,
  DELIVERY_ROUTE,
  MAIN_ROUTE,
  REGISTRATION_ROUTE,
  SHOP_ROUTE
} from 'constants/consts'
import Contacts from 'pages/contacts/Contacts'

export const publicRoutes = [
  {
    path: MAIN_ROUTE,
    Component: <MainPage />
  },
  {
    path: CATALOG_ROUTE,
    Component: <Catalog />
  },
  {
    path: CATALOG_ROUTE + '/:id',
    Component: <Catalog />
  },
  {
    path: CATALOG_ROUTE + '/:id' + '/:product_id',
    Component: <ProductCard />
  },
  {
    path: REGISTRATION_ROUTE,
    Component: <Registration />
  },
  {
    path: CARD_ROUTE + '/:id_1c',
    Component: <ProductCard />
  },
  {
    path: CATALOG_ROUTE + CATEGORY_ROUTE + '/:id',
    Component: null
  },
  {
    path: CONTACTS_ROUTE,
    Component: <Contacts />
  },
  {
    path: CONFIRMATION_ROUTE,
    Component: <ConfirmOrder />
  }
]

export const privateRoutes = [
  {
    path: MAIN_ROUTE,
    Component: <MainPage />
  },
  {
    path: CATALOG_ROUTE,
    Component: <Catalog />
  },
  {
    path: CATALOG_ROUTE + '/:id',
    Component: <Catalog />
  },
  {
    path: CATALOG_ROUTE + '/:id' + '/:product_id',
    Component: <ProductCard />
  },
  {
    path: CONFIRMATION_ROUTE,
    Component: <ConfirmOrder />
  },
  {
    path: CONTACTS_ROUTE,
    Component: <Contacts />
  }
]
