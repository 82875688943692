import { CATALOG_ROUTE } from 'constants/consts'
import altLogo from 'img/altLogo.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCategoryName, setProducts } from 'redux/toolkitSlice'

const SubMenuItem = ({ item, setMenuActive, setOpenSubMenu, categoryParentId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleClickOnSubMenuItem() {
    if (item.products.length > 0) {
      dispatch(setCategoryName(item.name))
      dispatch(setProducts(item.products))
    } else {
      dispatch(setProducts([]))
    }
    navigate(CATALOG_ROUTE + `/${item.id}`)
    setMenuActive(false)
    setOpenSubMenu(false)
  }

  return (
    <div
      className='w-40 text-center cursor-pointer p-2 rounded-md hover:bg-gray-200'
      onClick={handleClickOnSubMenuItem}
    >
      <div className='block w-40'>
        <img className='w-full h-full' src={item.img || altLogo} alt='' />
      </div>
      <div className='break-words'>{item.name}</div>
    </div>
  )
}

export default SubMenuItem
