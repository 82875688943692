import { fetchCategoryByID, fetchCategoryWithoutParent } from 'http/productAPI'
import Categories from 'pages/catalog/Categories'
import Products from 'pages/catalog/Products'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setCategories, setProducts } from 'redux/toolkitSlice'

const Catalog = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const { products } = useSelector(state => state.toolkit)

  async function getCategories() {
    setLoading(true)
    if (id) {
      const data = await fetchCategoryByID(id)
      dispatch(setCategories(data))
    } else {
      const data = await fetchCategoryWithoutParent()
      dispatch(setCategories(data))
      dispatch(setProducts([]))
    }
    setLoading(false)
  }

  useEffect(() => {
    getCategories()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className='h-fit min-h-screen'>
      <div className='relative top-20 w-full'>
        {products.length > 0 ? <Products products={products} loading={loading} /> : <Categories loading={loading} />}
      </div>
    </div>
  )
}

export default Catalog
