import { Scrollama, Step } from 'react-scrollama';
import React, { useState } from 'react';
import steelFiting from '../../img/steelFiting.png'
import { useNavigate } from 'react-router-dom';
import { CATALOG_ROUTE } from 'constants/consts';

const AnimationComponent2 = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };
  const navigate = useNavigate();
  return (
    <Scrollama offset={0.7} onStepEnter={onStepEnter}>
          <Step data={1}>
               <div className='Industry__content'>
                    <div className={currentStepIndex === 1 ? 'Industry__text' : 'Industry__textInvisible'}>
                        <p className='Industry__topText'><span>Власне виробництво</span></p>
                        <p className='Industry__bottomText'><span>Фітинг стальний</span></p>
                        <input className='Slider__button' type='button' value='Каталог' onClick={() => navigate(CATALOG_ROUTE)}></input>
                    </div>
                    <div className={currentStepIndex === 1 ? 'Industry__contentImg' : 'Industry__contentImgInvisible'}>
                        <img src={steelFiting} alt="asort" />
                    </div>
                </div>
          </Step>

      </Scrollama>

  );
};

export default AnimationComponent2;
