import PriceForRetailOrWholesale from 'components/PriceForRetailOrWholesale'
import altLogo from 'img/altLogo.png'
import React from 'react'

const ProductItem = ({ product, actionButtons, quantity = false }) => {
  return (
    <div className='flex items-center justify-between flex-col w-full mt-1 p-2 rounded-md border border-solid border-gray-200 flex-wrap gap-1'>
      <div className=' w-full flex items-center justify-start gap-2'>
        <div className='block w-14 mr-1'>
          <img
            className='w-full h-full'
            src={product.img && product.img.length > 0 ? product.img[0] : altLogo}
            alt='product'
          />
        </div>
        <p className='line-clamp-2 break-words '>{product.name}</p>
      </div>
      <div className='w-full flex items-center justify-between gap-2'>
        <div className=''>
          Ціна: <PriceForRetailOrWholesale price={product.priceUSD} /> грн
        </div>
        {/*quantity*/}
        {quantity && (
          <div>
            К-сть: <input className='p-2 w-20' type={'number'} min={1} value={product.quantity} onChange={e => null} />
          </div>
        )}
        {quantity && (
          <div>
            Cума: <PriceForRetailOrWholesale price={product.amount} /> грн
          </div>
        )}
        <div>{actionButtons}</div>
      </div>
    </div>
  )
}

export default ProductItem
