import React from 'react'
import { ReactComponent as Star } from 'img/icons/star-filled.svg'

const RateAndProductId = ({ product }) => {
  return (
    <div className='flex items-center justify-between my-2'>
      <div className='flex items-center'>
        <Star className='w-6 h-4 fill-amber-300' />
        <Star className='w-6 h-4 fill-amber-300' />
        <Star className='w-6 h-4 fill-amber-300' />
        <Star className='w-6 h-4 fill-amber-300' />
        <Star className='w-6 h-4 fill-amber-300' />
      </div>
      <div className='text-gray-500'>ID: {product.id}</div>
    </div>
  )
}

export default RateAndProductId
